<template>
  <div class="row">
    <div class="col-12 col-lg-8 c">
      <div class="card">
        <div class="card-header">
          <h4>
            المناوبة
          </h4>
        </div>
        <div class="card-body">
          <div class="g">
            <button class="btn btn-sm btn-primary" @click="getTeachersMessages()" v-b-modal.send>
              <i class="fa fa-paper-plane"></i>
              ارسال جدول المناوبة للمعلمين
            </button>
            &nbsp;
            <button class="btn btn-sm btn-warning" @click="getTeachersMessages()" v-b-modal.auto>
              <i class="fa fa-clock-o"></i>
              ارسال المناوبة تلقائياً بشكل يومي للمعلمين 
              <span v-if="send_teachers_shifts.enabled" class="text-success bg-white" style="border-radius: 6px; padding-right: 5px; padding-left: 5px">
                (<i class="fa fa-check-square"></i>
                فعالة)
              </span>
              <span v-if="!send_teachers_shifts.enabled" class="text-danger bg-white" style="border-radius: 6px; padding-right: 5px; padding-left: 5px">
                غير <i class="fa fa-times"></i>
                فعالة
              </span>
            </button>
            &nbsp;
            <button class="btn btn-sm btn-info" @click="print()">
              <i class="fa fa-print"></i>
              طباعة
            </button>
          </div>
          <div class="col-12 table-responsive">
          <div id="p" class="dnone">
            <table class="table table-bordered table-striped">
            <tbody>
                <tr>
                  <th rowspan="2" class="bg-dark  text-center">اليوم</th>
                  <td colspan="1" class="bg-dark  text-center">الـمـنـاوبـة *</td>
                </tr>
                <tr>
                  <td class="bg-dark  text-center">الإسم **</td>
                </tr>
                <template v-for="d in days">
                  <tr :key="d">
                    <td :rowspan="shifts[d].length == 0 ? 1 : shifts[d].length" class="bg-dark  text-center">{{ dayname(d) }}</td>
                    <td>{{ shifts[d][0] }}</td>
                  </tr>
                  <template v-for="(t, i) in shifts[d]">
                  <tr v-if="i != 0" :key="t">
                    <td>
                      {{ t }}
                    </td>
                  </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
              <table class="table table-hover table-bordered">
                  <thead>
                      <th>
                          اليوم
                      </th>
                      <th>
                          المعلمين
                      </th>
                      <th>
                          اضافة معلم
                      </th>
                  </thead>
                  <tbody>
                      <tr v-for="d in days" :key="d">
                          <td>
                              {{ dayname(d) }}
                          </td>
                          <td>
                              <ul>
                                  <li v-for="t in shifts[d]" :key="t">{{ t }} <u class="text-danger" @click="deleteMe(d, t)">حذف</u></li>
                              </ul>
                          </td>
                          <td>
                              <select v-model="addme" @change="addMeNow(d)">
                                  <option value="">-- اختر للاضافة --</option>
                                  <option v-for="t in teachers" :key="t.name" :value="t.name">
                                      {{ t.name }}
                                  </option>
                              </select>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
        </div>
      </div>
    </div>
    <!-- basic modal -->
    <b-modal id="send" title="ارسال" hide-footer size="lg">
      <div class="form-group">
        <label for="">الرسالة <a href="javascript:;" @click="templates('asd433x45')"><u><i class="fa fa-comment"></i> قوالب الرسائل</u></a></label>
        <textarea class="form-control asd433x45" v-model="message" rows="3"></textarea>
      </div>
      <hr>
      <div class="row">
          <div class="col-12 col-lg-4 g">
              <button class="btn btn-success btn-block"  @click="send('whatsapp')">
                  <i class="fa fa-whatsapp"></i>
                  واتس تلقائي
              </button>
          </div>
          <div class="col-12 col-lg-4 g">
              <button class="btn btn-success btn-block" @click="send('wa')">
                  <i class="fa fa-whatsapp"></i>
                  اداة المتصفح
              </button>
          </div>
          <div class="col-12 col-lg-4 g">
              <button class="btn btn-primary btn-block"  @click="send('sms')">
                  <i class="fa fa-mobile"></i>
                  SMS
              </button>
          </div>
      </div>
    </b-modal>
    <b-modal id="auto" title="ارسال تلقائي" hide-footer size="sm">
      <div class="form-check border" style="border-radius: 5px; padding: 10px; padding-right: 20px; background: #FEFAE0">
        <label class="form-check-label">
          <input type="checkbox" class="form-check-input" v-model="send_teachers_shifts.enabled" :value="true">
          <h5>
            تفعيل الخاصية
          </h5>
        </label>
      </div>
      <hr>
      <div class="form-group">
        <label for="">الرسالة <a href="javascript:;" @click="templates('asd43345')"><u><i class="fa fa-comment"></i> قوالب الرسائل</u></a></label>
        <textarea class="form-control asd43345" v-model="send_teachers_shifts.message" rows="3"></textarea>
      </div>
      <hr>
      <div class="form-group">
        <label for="">توقيت الارسال</label>
        <input type="time"
          class="form-control" v-model="send_teachers_shifts.time">
      </div>
      <hr>
      <div class="form-group">
        <label for="">ارسال عبر</label>
        <select class="form-control" v-model="send_teachers_shifts.method">
          <option value="sms">SMS</option>
          <option value="whatsapp">الواتس الذكي (الواتس التلقائي)</option>
        </select>
        <small>* تأكد من ضبط اعدادات الsms او الواتس الذكي.</small>
      </div>
      <div class="col-12 text-center">
        <button class="btn btn-success" @click="savesh()">
          <i class="fa fa-check"></i>
          حفظ الاعدادات
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BCard, BCardText, BLink, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
const axios = require("axios");
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
        teachers: [],
        shifts: {},
        xx: {},
        loading: true,
        send_teachers_shifts: {
          enabled: false,
          time: "07:00",
          method: "whatsapp",
          message: `المعلم {name} لديك اليوم مناوبة.`
        },
        user: JSON.parse(localStorage.getItem('user')),
        days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday"],
        addme: "",
        message: `الاستاذ {name}
جدول المناوبة:
{days}`
    };
  },
  methods: {
        templates(x){
            $("#templates").click()
            $("#inserttemplateto").val(x)
        },
    savesh(){
        var g = this;
        axios
        .post(api + "/user/teachers/shifts/save-settings", {
            jwt: g.user.jwt,
            settings: JSON.stringify(g.send_teachers_shifts)
        })
        .then(function (r) {
          if(r.data.status == 100){
            alert("تم الحفط بنجاح", 100)
          }else{
            alert(r.data.response, 200)
          }
        })
    },
    send(method){
    var g = this;
    var messages = [];
    g.teachers.forEach(function(a){
        if(g.xx[a.name]){
          messages.push({
            phones: [a.phone],
            message: g.generateMessage(a)
        })
        }
    })
    if(method == 'wa'){
        $("#sendmodalwaarray").val(JSON.stringify(messages))
        $("#sendmodalwatype").val("students")
        $("#sendWAModal").click()
    }else{
        $.post(api + '/user/messages/send-arr', {
            jwt: g.user.jwt,
            arr: JSON.stringify(messages),
            method: method,
            type: "teachers"
        }).then(function(r){
            g.loaded = true;
            if(r.status != 100){
                alert(r.response, 200)
            }else{
                alert("تم الارسال بنجاح٫ يمكنك تصفح ارشيف الرسائل", 100)
            }
        }).fail(function(){
            alert("حدث خطأ", 200)
        })
    }
},
    generateMessage(t){
      var msg = this.message;
      msg = msg.replace('{name}', t.name);
      msg = msg.replace('{days}', this.xx[t.name].map(function(d){
        d = d.replace("Sunday", "الاحد");
        d = d.replace("Monday", "الاثنين");
        d = d.replace("Tuesday", "الثلاثاء");
        d = d.replace("Wednesday", "الاربعاء");
        d = d.replace("Thursday", "الخميس");
        d = d.replace("Friday", "الجمعة");
        d = d.replace("Saturday", "السبت");
        return d
      }).join(","))
      return convertToHijri(msg);
    },
    getTeachersMessages(){
      var g = this;
      g.teachers.forEach(function(teacher){
        g.xx[teacher.name] = false;
        g.days.forEach(function(d){
          g.shifts[d].forEach(function(c){
            if(!g.xx[c]){
              g.xx[c] = []
            }
            if(g.xx[c].includes(d)){return;}
            g.xx[c].push(d);
          })
        })
      })
      g.xx = JSON.parse(JSON.stringify(g.xx))
    },
    deleteMe(d, t){
        var g = this, arr = [];
        this.shifts[d].forEach(function(a){
            if(a != t){
                arr.push(a)
            }
        })
        this.shifts[d] = JSON.parse(JSON.stringify(arr))
        this.shifts = JSON.parse(JSON.stringify(this.shifts))
        g.save()
    },
    addMeNow(d){
        this.shifts[d].push(this.addme)
        this.addme = ""
        this.save()
    },
    dayname(d){
        d = d.replace("Sunday", "الاحد");
        d = d.replace("Monday", "الاثنين");
        d = d.replace("Tuesday", "الثلاثاء");
        d = d.replace("Wednesday", "الاربعاء");
        d = d.replace("Thursday", "الخميس");
        d = d.replace("Friday", "الجمعة");
        d = d.replace("Saturday", "السبت");
        return d;
    },
    save(){
        var _g = this;
        axios
        .post(api + "/user/teachers/shifts/save", {
            jwt: _g.user.jwt,
            shifts: JSON.stringify(_g.shifts)
        })
        .then(function (r) {
            if (r.data.status == 200) {
            _g.$toast({
                component: ToastificationContent,
                props: {
                title: "حدث خطأ JWT",
                icon: "WarningIcon",
                variant: "danger",
                },
            });
            } else {
            _g.loading = false;
            }
        })
        .catch(function () {
            _g.$toast({
            component: ToastificationContent,
            props: {
                title: "حدث خطأ",
                icon: "WarningIcon",
                variant: "danger",
            },
            });
        });
    },
    print(){
var g = this;
      var win = window.open("");
      win.document.write(`
      <html dir="rtl">
        <head>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css">
          <style>
            .bg-dark{
            background: #DDDDDD !important
            }
          </style>
        </head>
        <body>
        <div class='container'>
          <div class="row" style='padding-top: 10px'>
            <div class="col-4 text-center">
              <p>
                المملكة العربية السعودية
                <br>
                وزارة التعليم
                <br>
                ${g.info.school_name}
              </p>
            </div>
            <div class="col-4 text-center">
            <img style="width: 150px !important;" src="https://cdn-static.brmja.com/storage/scrapped/62793ceb03c19132886968.jpg">
            </div>
            <div class="col-4 text-center">
              <p style='padding-top: 5px'>
              رمز النموذج:( و.ت.ع.ن ‐ ٠١ ‐ ٠٢ )
              <br>
              التاريخ: ${new Date().toISOString().split("T")[0]}
              </p>
            </div>
            <div class='col-12 text-center'>
              <p>
                <strong>اسم النموذج: جدول المناوبة اليومي</strong>
                <br>
                العام الدراسي ١٤٤٦ هـ / الفصل الدراسي الثالث
              </p>
            </div>
            <div class='col-12'>
              ${$("#p").html()}
            </div>
            <div class='col-6'>
              وكيل الشؤون التعليمية :
              <br>
              الإسم :
              <br>
              التوقيع :
            </div>
            <div class='col-6'>
              وكيل الشؤون التعليمية :
              <br>
              الإسم :
              <br>
              التوقيع :
            </div>
            <div class='col-12'>
            <br>
            * لا يشترط أن ييلف الموظف بالمناوبة والإشراف في يوم واحد.
          <br> ** لا يقل الحد الأدني للمناوبة والإشراف عن موظفين ويمكن زيادة العدد حسب الاحتياج.
            </div>
          </div>
        </div>
        </body>
      </html>
      `)
      win.document.close()
      setTimeout(() => {
        win.print()
      }, 1000);
    }
  },
  created() {
      if(!checkPer("teachers|shifts")){
          this.$router.push('/NotPermitted')
          return false;
      }
    var _g = this;
        axios
        .post(api + "/user/teachers/shifts/settings", {
            jwt: _g.user.jwt
        })
        .then(function (r) {
          if(r.data.response){
            _g.send_teachers_shifts = r.data.response
          }
        })
    var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    days.forEach(function(a){
        _g.shifts[a] = [];
    })
    axios
    .post(api + "/user/teachers/list", {
        jwt: _g.user.jwt
    })
    .then(function (r) {
        if (r.data.status == 200) {
        _g.$toast({
            component: ToastificationContent,
            props: {
            title: "حدث خطأ JWT",
            icon: "WarningIcon",
            variant: "danger",
            },
        });
        } else {
        _g.teachers = r.data.response;
        _g.loading = false;
        }
    })
    .catch(function () {
        _g.$toast({
        component: ToastificationContent,
        props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
        },
        });
    });
    axios
      .post(api + "/user/teachers/shifts", {
        jwt: _g.user.jwt
      })
      .then(function (r) {
        if (r.data.status == 200) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          try{
            if(r.data.response){
                _g.shifts = JSON.parse(r.data.response);
            }
          }catch{
            days.forEach(function(a){
                _g.shifts[a] = [];
            })
          }
          _g.loading = false;
        }
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      });
      $.post(api + '/user/teachers/info', {
        jwt: _g.user.jwt
      }).then(function(r){
        _g.info = r.response
      })
  },
};
</script>

<style>
</style>
